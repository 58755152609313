<!--
 * @Author: pengmingjun 1317525216@qq.com
 * @Date: 2022-05-11 15:04:21
 * @LastEditors: pengmingjun 1317525216@qq.com
 * @LastEditTime: 2022-05-25 17:36:34
 * @FilePath: \teacher.dingdingkaike\src\components\ImgLazyload.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-image class="lazy-img" :src="src" lazy></el-image>
</template>
<script>
export default {
  name: 'courseCover',

  props: {
    src: String,
  },
}
</script>
<style scoped>
.lazy-img {
  max-width: 100%;
  display: block;
}
</style>
